<template>
  <div class="col px-0">
    <div class="row px-1 mb-3 equal-height">
      <div class="col-12 col-lg-7" style="padding: 20px; background-color: #283046; border-radius: 5px;">
        <div>
          <div
            v-if="live !== null"
          >
            <div
              v-if="liveSpeaker.password_vmix"
              class="embed-responsive embed-responsive-16by9"
            >
              <div v-if="!(sameDevice)">
                Conectando em {{ 10 - timer }} ...
              </div>
              <iframe
                :src="`https://advanced.vmixcall.com/call.htm?Key=${liveSpeaker.password_vmix}&Name=${liveSpeaker.speaker.name_badge}`"
                width="100%"
                height="280"
                allow="camera;microphone;fullscreen"
                style="border:none;"
                allowfullscreen=""
              />
            </div>
            <div v-else>
              <iframe
                v-if="live.type === 'cloudflare' || live.type === 'Cloudflare'"
                id="cloudflare"
                :src="vimeo_base + live.url + '?preload=true&loop=true&autoplay=true'"
                style="left: 0px; width: 100%; border: 0"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
                width="100%"
                height="280"
                loop="true"
              />
              <div class="video-container">
                <vue-plyr
                  v-if="live.type === 'youtube' || live.type === 'Youtube'"
                  :options="options"
                >
                  <div
                    data-plyr-provider="youtube"
                    :data-plyr-embed-id="live.url"
                    allow="autoplay"
                  />
                </vue-plyr>
              </div>

              <iframe
                v-if="live.type === 'vimeo' || live.type === 'Vimeo'"
                :src="'https://player.vimeo.com/video/'+live.url+'?autoplay=true&loop=false&byline=false&portrait=false&title=false&speed=true'"
                frameborder="0"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;"
                allowfullscreen
                style="overflow:hidden;width:100%"
                width="100%"
                height="280"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-5" style="padding: 20px; border-radius: 5px; min-height: 380px">
        <chat
        :live-id="live.id"
        :user-id="sessionInfo.user.id"
        style="margin:0px;height: 100%;"
      />
      </div>
    </div>
    <div class="row px-1 mb-2" style="min-height: 211px;">
      <div class="col-lg-8 col-md-6 px-0 my-1">
        <div class="row px-1 align-items-baseline" style="height: 25px;">
          <h5 style="margin-right: 5px;">Título:</h5>
          <p>{{ live.title }}</p>
        </div>
        <div class="row px-1 align-items-baseline" style="height: 25px;">
          <h5 style="margin-right: 5px;">Assunto:</h5>
          <p>-</p>
        </div>
        <div class="row px-1 align-items-baseline" style="height: 25px;">
          <h5 style="margin-right: 5px;">Data da palestra:</h5>
          <p>{{ date }}</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 my-1">
       
      </div>
    </div>
  </div>
</template>

<script>
import LivesServices from './LivesServices'
import chat from '@/components/chat/ChatLive.vue'

export default {
  components: {
    chat
  },
  data() {
    return {
      youtube_base: 'https://www.youtube.com/embed/',
      vimeo_base: 'https://iframe.videodelivery.net/',
      // eslint-disable-next-line radix
      slug: parseInt(this.$route.params.slug),
      liveSpeaker: {},
      live: {},
      loading: false,
      options: {
        autoplay: true,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
      },
      seconds_watched: 0,
      date: ''
    }
  },
  computed: {
    sessionInfo() {
      return this.$store.state.auth.sessionInfo
    },
  },
  async mounted() {
    await LivesServices().getLive({ slug: this.$route.params.slug }).then(api => {
      this.live = api.data.response.live
    })

    setInterval(() => {
      LivesServices().getLive({ slug: this.$route.params.slug }).then(api => {
        this.live = api.data.response.live
      })
    }, 30000)

    LivesServices().getLiveSpeakerByLive({
      live_id: this.live.id,
    }).then(api => {
      this.liveSpeaker = api.data.response.liveSpeaker
    })

    setInterval(() => {
      // eslint-disable-next-line no-plusplus
      this.seconds_watched++

      if (this.seconds_watched === 30) {
        LivesServices().liveMonitoring({
          live_id: this.live.id,
          seconds_watched: this.seconds_watched
        })
        this.seconds_watched = 0
      }
    }, 1000)

    let listDate = this.live.date.split('-')
    let months = ['janeiro', 'fevereiro', 'março','abrril', 'maio', 'junho', 'julho', 'agosto', 'setembo', 'outubro', 'novembro', 'dezembro']
    this.date = `${listDate[2]} de ${months[parseInt(listDate[1])-1]} de ${listDate[0]}`
  }
}
</script>

<style>
.video-container {
  width: 100%; /* Defina a largura desejada */
  max-width: 800px; /* Defina a largura máxima desejada */
  margin: 0 auto; /* Centralize o componente horizontalmente */
}
.equal-height {
  display: flex;
}
.equal-height .col-7,
.equal-height .col-5 {
  display: flex;
  flex-direction: column;
}
</style>
